import settings from '@/settings'

class _Auth {
  login(body) {
    return axios.post(`${settings.api.userPath}/login`, body)
  }
  logout(body) {
    return axios.post(`${settings.api.bopPath}/user/logout`, body)
  }
  register(body) {
    return axios.post(`${settings.api.bopPath}/user/register`, body)
  }
  getAllUsers() {
    return axios.get(`${settings.api.bopPath}/user/getAllUsers`)
  }
  setAuth(body) {
    return axios.post(`${settings.api.bopPath}/user/setAuth`, body)
  }
  getAuth() {
    return axios.get(`${settings.api.bopPath}/user/getAuth`)
  }
}

let Auth = new _Auth()
export default Auth
