import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import '@/assets/css/index.scss'
import '@vuepic/vue-datepicker/dist/main.css'
import mitt from 'mitt'
import './vendor' // 其他套件
window.emmiter = mitt()
window._ = {}
window._.cloneDeep = require('lodash/cloneDeep')
window._.debounce = require('lodash/debounce')
window.mediaStream = null

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
