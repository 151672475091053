module.exports = {
  title: '充電樁管理',
  publicPath: process.env.VUE_APP_PUBLICPATH || '/',
  version: require('../package.json').version,
  versionTime: process.env.VUE_APP_VERSION_TIME,
  mapToken: process.env.VUE_APP_MAP_TOKEN,
  api: {
    userPath: process.env.VUE_APP_USER_API_URL,
    bopPath: process.env.VUE_APP_BOP_API_URL,
    devicePath: process.env.VUE_APP_DEVICE_API_URL,
    orderPath: process.env.VUE_APP_ORDER_API_URL,
    webappPath: process.env.VUE_APP_WEBAPP_API_URL,
    springPointPath: `${process.env.VUE_APP_SPRING_API_URL}/points/api/v1`,
    springSysConfigPath: `${process.env.VUE_APP_SPRING_API_URL}/systemConfiguration`,
    springFeaturePath: `${process.env.VUE_APP_SPRING_API_URL}/featureToggle/api/v1`,
    springDevicePath: `${process.env.VUE_APP_SPRING_API_URL}/device/api/v1`,
    springOrderPath: `${process.env.VUE_APP_SPRING_API_URL}/order/api/v1`,
    springUserPath: `${process.env.VUE_APP_SPRING_API_URL}/user/api/v1`,
  }
}