<template>
  <v-app class="touch-none bg-gray-100 h-full">
    <v-main class="h-full">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted () {
    this.$store.dispatch('fetchIsOffPeakChargingEnabled')
    this.$store.dispatch('fetchOffPeakChargingDevices')
  }
}
</script>