
export default {
  namespace: true,
  state: {
    mapInit: false
  },
  getters: {
    getMapInit(state) {
      return state.mapInit
    }
  },    
  mutations: {
    SET_MAPINIT(state, bool) {
      state.mapInit = bool
    }
  },
  actions: {

  },
}