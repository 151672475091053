// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme: {
          dark: false,
          colors: {
            cyan800: '#0c2f4b',
            gray300: '#a5b1ba',
            slate500: '#798b98',
            blue700: '#1565C0',
            orange600: '#ea580c',
          },
          variables: {
            // 'border-color': 'inherit',
          }
        }
      }
    }
  }
)
