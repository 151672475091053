import settings from '@/settings'

class _Feature {
  isOffPeakChargingEnabled() {
    return axios.get(`${settings.api.springFeaturePath}/isOffPeakChargingEnabled`)
  }
}

let Feature = new _Feature()
export default Feature
