
import store from '@/store'
const protectedFun = (to, from, next) => {
  // store.getters.getAccessToken ? next() : next('/login')
  if(store.getters.getAccessToken) {
    next()
  }else {
    sessionStorage.setItem('redirectURL', to.fullPath)
    next({
      path: '/login'
    })
  }
}
const publicFun = (to, from, next) => {
  store.getters.getAccessToken ? next('/map') : next()
}
const invoiceResetRedirect = (to, from, next) => {
  if(sessionStorage.getItem('IS_INVOICE_RESET')) {
    sessionStorage.removeItem('IS_INVOICE_RESET');
    next({
      path: '/charging-history'
    })
  } else{
    next()
  }
}
export const publicRoute = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login'
    },
    component: () => import('@/views/auth/Login.vue'),
    beforeEnter: publicFun
  },
  {
    path: '/login-success',
    name: 'login-success',
    component: () => import('@/views/auth/LoginSuccess.vue'),
    beforeEnter: publicFun
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/Map.vue'),
    beforeEnter: protectedFun
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: () => import('@/views/Scanner.vue'),
    beforeEnter: protectedFun
  },
  {
    path: '/user-info-detail',
    name: 'user-info-detail',
    component: () => import('@/views/UserInfoDetail.vue'),
    beforeEnter: protectedFun
  },
  {
    path: '/charging-history',
    name: 'charging-history',
    component: () => import('@/views/ChargingHistory.vue'),
    beforeEnter: protectedFun
  },
  {
    path: '/payment-settings',
    name: 'payment-settings',
    component: () => import('@/views/PaymentSettings.vue'),
    beforeEnter: [protectedFun, invoiceResetRedirect]
  },
  {
    path: '/charging',
    name: 'charging',
    component: () => import('@/views/Charging.vue'),
    beforeEnter: protectedFun
  },
  {
    path: '/charging-done',
    name: 'charging-done',
    component: () => import('@/views/ChargingDone.vue'),
    beforeEnter: protectedFun
  },
  {
      path: '/redirectMiddleware',
      name: 'redirectMiddleware',
      component: () => import('@/components/RedirectMiddleware.vue'),
      beforeEnter: protectedFun
  },
  {
    path: "/app",
    name: "app",
    component: () => import("@/views/AppLink.vue"),
  },
  {
    path: '/:catchAll(.*)',
    redirect(to) {
      console.log(to)
      if (to.path.includes('.html')) {
        return {
          path: to.path.replace('.html', ''),
          query: to.query
        }
      }
      const name = store.getters.getAccessToken ? 'map' : 'login'
      return { name }
    }
  }
]
export const protectedRoute = [
  {
    path: '/map',
    component: () => import('@/views/Map.vue')
  },
  {
    path: '/scanner',
    component: () => import('@/views/Scanner.vue')
  },
  {
    path: '/user-info-detail',
    component: () => import('@/views/UserInfoDetail.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/map',
    // component: DefaultContainer,
  }
]