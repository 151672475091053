import settings from '@/settings'

class _User {
  getUserInfoByToken(token) {
    return axios.get(`${settings.api.userPath}/getUserInfoByToken`, { headers: { token } })
  }
  setUserInfo(body, token) {
    return axios.post(`${settings.api.userPath}/setUserInfo`, body, { headers: { token } })
  }
  setUserPaymentChannel(token, body) {
    return axios.post(`${settings.api.userPath}/setUserPaymentChannel`, body, { headers: { token } })
  }
  getUserPaymentChannel(params){
    return axios.get(`${settings.api.userPath}/getUserPaymentChannel`, { params: params })
  }
  checkLinePayRegKey(token) {
    return axios.get(`${settings.api.springUserPath}/linePay/checkRegKeyStatus`, { headers: { token } })
  }
  autoPaymentApply(token, body) {
    return axios.post(`${settings.api.springUserPath}/linePay/autoPaymentApply`, body, { headers: { token } })
  }
  expireLinePayRegKey(token, body) {
    return axios.post(`${settings.api.springUserPath}/linePay/expireRegKey`, body, { headers: { token } })
  }
  getUserLinePayBindCreditCardNo(token) {
    return axios.get(`${settings.api.springUserPath}/linePay/getBindCreditCardNo`, { headers: { token } })
  }
  checkUserInvoiceProfile(token) {
    return axios.get(`${settings.api.springUserPath}/checkUserInvoiceProfile`, { headers: { token } })
  }
}

let User = new _User()
export default User
