import settings from '@/settings'

class _SystemConfig {
  offPeakChargingDevices() {
    return axios.get(`${settings.api.springSysConfigPath}/offPeakChargingDevices`)
  }
}

let SystemConfig = new _SystemConfig()
export default SystemConfig
