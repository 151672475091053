export default function Cookie(key, value, expireDays) {
  this.key = key;
  this.value = value;
  this.expireDays = expireDays;
}

Cookie.prototype.addCookie = function () {
  var expires = "";
  if (this.expireDays) {
      var date = new Date();
      date.setTime(date.getTime() + (this.expireDays * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = this.key + "=" + (this.value || "") + expires + "; path=/";
};

Cookie.prototype.readCookie = function () {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${this.key}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

Cookie.prototype.deleteCookie = function () {
  document.cookie = this.key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
};

Cookie.prototype.saveCurrentPath = function () {
  let cookie = new Cookie('ev_redirect', window.location.href);
  cookie.addCookie();
  return this;
};