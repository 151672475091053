import Auth from '@/api/Auth.js'
import User from '@/api/User.js'
import Feature from '@/api/Feature.js'
import SystemConfig from '@/api/SystemConfig.js'
// import md5 from 'md5'
import { setRouter } from '@/router/index.js'
import { publicRoute, protectedRoute } from '@/router/config.js'
import WebApp from '@/api/WebApp'
import Cookie from '@/assets/js/Cookie'
export default {
  namespace: true,
  state: {
    token: null,
    refreshToken: null,
    auth: {
      userId: null,
      userType: null,
    },
    isOffPeakChargingEnabled: false,
    offPeakChargingDevices: []
  },
  getters: {
    getAccessToken: (state) => {
      return state.token
    },
    getAuth: (state) => {
      return state.auth
    },
    getIsOffPeakChargingEnabled: (state) => {
      return state.isOffPeakChargingEnabled
    },
    getOffPeakChargingDevices: (state) => {
      return state.offPeakChargingDevices
    }
  },
  mutations: {
    SET_LOGIN(state, { refreshToken, token }) {
      state.token = token
      state.refreshToken = refreshToken
    },
    SET_ACCESS_TOKEN(state, token) {
      state.token = token
    },
    SET_AUTH(state, obj) {
      state.auth.userId = obj.userId
      state.auth.userType = obj.userType
    },
    SET_IS_OFF_PEAK_CHARGING_ENABLED(state, bool) {
      state.isOffPeakChargingEnabled = bool
    },
    SET_OFF_PEAK_CHARGING_DEVICES(state, arr) {
      state.offPeakChargingDevices = arr
    }
  },
  actions: {
    login({ commit, dispatch }, { vueInstance }) {
      // return Auth.login({ id, password: md5(password), channel: 'LOCAL' })
      return WebApp.getUser().then(({ data }) => {
        const obj = {
          refreshToken: data.token,
          token: data.token
        }
        const obj2 = {
          userId: data.userId,
          userType: data.userType
        }
        commit('SET_LOGIN', obj)
        commit('SET_AUTH', obj2)
        const redirectUrl = sessionStorage.getItem("redirectURL") || '/map'
        vueInstance.$router.push(redirectUrl)
      }) 
    },
    logout({ commit, dispatch }, vueInstance) {
      return new Promise(resolve => {
        try {
          let cookie = new Cookie('ev_redirect');
          cookie.deleteCookie();
          // let fetuidCookie = new Cookie('fetuid');
          // fetuidCookie.deleteCookie();
        }catch(e) {
          console.log(e);
        }
        // setRouter(vueInstance.$router, publicRoute)
        commit('SET_ACCESS_TOKEN', null)
        commit('SET_AUTH', {} )
        resolve()
      })
    },
    async getUserInfoByToken({ getters, commit }) {
      try {
        const { data } = await User.getUserInfoByToken(getters.getAccessToken)
        if (!data.userInfo) return
        const obj = {
          userId: data.userInfo.userId,
          userType: data.userInfo.userType
        }
        commit('SET_AUTH', obj)
      } catch (e) {
        console.error(e)
      }
    },
    async fetchIsOffPeakChargingEnabled({ commit }) {
      try {
        const { data } = await Feature.isOffPeakChargingEnabled()
        commit('SET_IS_OFF_PEAK_CHARGING_ENABLED', data.body)
      } catch (e) {
        console.error(e)
      }
    },
    async fetchOffPeakChargingDevices({ commit }) {
      try {
        const { data } = await SystemConfig.offPeakChargingDevices()
        commit('SET_OFF_PEAK_CHARGING_DEVICES', data.body)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
