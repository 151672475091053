const state = {
    callbackPage: '',
    event: ''
};

const mutations = {
    setCallbackPage(state, callbackPage) {
        state.callbackPage = callbackPage;
    },
    setEvent(state, event) {
        state.event = event;
    }
};

const actions = {
    navigateTo({commit}, {callbackPage, event}) {
        commit('setCallbackPage', callbackPage);
        commit('setEvent', event);
    },
    navigateToMap({commit}, {event}) {
        commit('setCallbackPage', '/map');
        commit('setEvent', event);
    },
    cleanCallbackPage({commit}) {
        commit('setCallbackPage', '');
    },
    cleanNavigate({commit}) {
        commit('setCallbackPage', '');
        commit('setEvent', '');
    }
};

export default {
    namespaced: true, state, mutations, actions
};