import settings, {api} from '@/settings'

class _WebApp {
  getUser(body) {
    return axios.get(`${settings.api.webappPath}/cookies/getUser`, body)
  }
  logout() {
    return axios.get(`${settings.api.webappPath}/cookies/logout`)
  }
  invoiceSetting(paymentChannel, token){
    if(paymentChannel === 'FETNET'){
      location.href = `${settings.api.webappPath}/broker/fetPaymentSettings?token=${token}`
    } else {
      location.href =  `${settings.api.webappPath}/broker/linePaySettings?token=${token}`
    }
  }
}

let WebApp = new _WebApp()
export default WebApp
