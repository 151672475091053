import { createRouter, createWebHistory } from 'vue-router'
import { publicRoute, protectedRoute } from './config'
import store from '@/store'
import settings from '@/settings'
// const routes = store.getters.getAccessToken ? protectedRoute : publicRoute
const routes = publicRoute

const createRouterFun = (routes) => {
  return createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: settings.publicPath,
    routes
  })
}

const router = createRouterFun(routes)

export const setRouter = (router, routes) => {
  router.matcher = createRouterFun(routes).matcher
  router.match = createRouterFun(routes).match
  router.options.routes = routes
}

const stopStream = () => {
  if(!window.mediaStream) return
  if(!window.mediaStream.getTracks) return
  window.mediaStream.getTracks().forEach(track => track.stop())
}

router.beforeEach((to, from, next) => {
  stopStream()
  const token = store.getters.getAccessToken
  if (!publicRoute.find((item) => item.path === to.path)) {
    if (token) {
      // setRouter(router, protectedRoute)
      next()
    } else {
      // setRouter(router, publicRoute)
      next({ path: '/login', query: { redirect: to.path } })
    }
  } else {
    // setRouter(router, publicRoute)
    next()
  }
  //auth route is authenticated
})

export default router
