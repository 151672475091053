import Vue from 'vue'

// moment
import moment from 'moment'
require('moment/locale/zh-tw')
moment.locale('zh-tw')
window.moment = moment

// axios
import axios from './axios'
